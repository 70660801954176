<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">

        <div class="card pfull__16 mb__12">
            <FieldGroup_info
                :field-group_class="'mb__20'"
                :field-group_icon_class="'icon_theme_secondary'"
                :icon="'circle__message'"
                :label="$t('Unit.Last message')"
                :value="timeAgoInfo"
            />

            <FieldGroup_info
                :field-group_class="'mb__20'"
                :icon="'circle__pin'"
                :label="$t('Unit.Address')"
                :value="address"
                :buttonExists="false"
                :value_button_icon="'common__copy'"
            />

            <FieldGroup_info
                :icon="'circle__coords'"
                :label="$t('Unit.Coords')"
                :value="latlng"
                :buttonExists="false"
                :value_button_icon="'common__copy'"
            />

        </div>

        <div class="card card_type_parameters-2-columns pfull__16 mb__12">
            <FieldGroup_info
                :icon="'circle__satellit'"
                :label="$t('Unit.Satellites')"
                :value="unitLmsg.sats"
            />

            <FieldGroup_info
                :icon="'circle__speed'"
                :label="$t('Unit.Max permissible speed')"
                :value="maxSpeed"
            />

            <FieldGroup_info
                :field-group_class="'mt__20'"
                :icon="'circle__parking'"
                :label="$t('Unit.Duration of parking')"
                :value="parkingDuration"
            />
        </div>

    </section>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'UnitInfo_Info',
        props: [
            'value',
            'unitId',
        ],
        components: {},
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "getAppUserSettings",
                "getAppUserUnits",
                "unitsByIds",
            ]),
            unitLmsg(){
                return this.value
            },
            timeAgo(){
                if (!this.unitLmsg) return false;
                return this.unitLmsg.timeAgo
            },
            timeAgoInfo() {
                if (this.timeAgo === false){
                    return '-';
                }
                let timeAgo = this.timeAgo
                //return timeAgo
                let time = {
                    y: Math.floor(timeAgo / (365 * 24 * 60 * 60)),
                    d: Math.floor(timeAgo / (24 * 60 * 60)),
                    h: Math.floor(timeAgo / (60 * 60)),
                    m: Math.floor(timeAgo / (60)),
                    s: Math.round(timeAgo / (1)),
                }
                // if(time.y) time = time.y+'y'
                // else
                if(time.d > 9) time = time.d+'d'
                else if(time.h) time = time.h+'h'
                else if(time.m) time = time.m+'m'
                else if(time.s) time = time.s+'s'

                //+'('+new Date(this.unitLmsg.time).toISOString()+')'
                return time ? this.$t('{time} ago', {time}) : ''
            },
            latlng(){
                return (this.unitLmsg && this.unitLmsg.latlng) ?
                    "lat: "+this.unitLmsg.latlng.lat+", lng: "+this.unitLmsg.latlng.lng+
                    //this.unitLmsg.latlng.lat+", "+this.unitLmsg.latlng.lng+
                    ' ('+this.$t('Last valid coordinates')+')' : ''
            },
            address(){
                return (this.unitLmsg && this.unitLmsg.address > ' ') ?
                    this.unitLmsg.address : ''
            },
            isParked(){
                return !this.unitLmsg.status ? null : this.unitLmsg.status.isParked
            },
            parkingDuration(){
                let timeAgo = (this.unitLmsg && this.unitLmsg.time && this.unitLmsg.time_speed) ?
                    this.unitLmsg.time-this.unitLmsg.time_speed+this.unitLmsg.timeAgo : false
                if (!this.isParked || !timeAgo){
                    return '-';
                }
                //return timeAgo
                let time = {
                    y: Math.floor(timeAgo / (365 * 24 * 60 * 60)),
                    d: Math.floor(timeAgo / (24 * 60 * 60)),
                    h: Math.floor(timeAgo / (60 * 60)),
                    m: Math.floor(timeAgo / (60)),
                    s: Math.round(timeAgo / (1)),
                }
                // if(time.y) time = time.y+'y'
                // else
                if(time.d > 9) time = time.d+'d'
                else if(time.h) time = time.h+'h'
                else if(time.m) time = time.m+'m'
                else if(time.s) time = time.s+'s'

                //+'('+new Date(this.unitLmsg.time).toISOString()+')'
                return time ? this.$t('{time} ago', {time}) : ''
            },
            unit(){
                return this.unitsByIds[this.unitId]
            },
            maxSpeed(){
                return this.unit.max_speed > 0 ? this.unit.max_speed+' '+this.speedUnits : ''
            },
            unit_icon(){
                return this.unit.icon || 'map__car_type_sedan'
            },
            speedUnits(){
                return this.getAppUserUnits.speed
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
        },
        created() {
            // console.log('UnitInfo_Info created', this.unitId, {...this.unit})
        },
        mounted() {
            // console.log('UnitInfo_Info mounted', this.unitId, {...this.unit})
        },
        updated() {
            // console.log('UnitInfo_Info updated', this.unitId, {...this.unit})
        },
    }
</script>
